header {
    background-color: #fafafa;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.block-layout {
    padding-left: 5%;
    padding-right: 5%;
}

.top-panel {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.top-panel-item {
    width: 25%;
}

.top-panel-logo {
    width: auto;
    height: 35px;
    top: 5px;
    position: relative;
}

.top-panel-logo.big{ display: none; }

.top-panel-title {
    color: #2e2d2f;
    font-size: 18px;
    padding: 10px 0;
    width: 50%;
    text-align: center;
}

    .top-panel-title a {
        color: #2e2d2f;
        font-size: 18px;
        text-decoration: none;
    }

#page-title i {
    margin-right: 15px;
    color: #2e2d2f;
}

.top-panel-user-options {
    font-size: 18px;
    padding: 10px 0;
    text-align: right;
}

.top-panel-user-settings {
    text-decoration: none;
    color: #33B58F;
    font-size: 15px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.top-panel-user-logout {
    text-decoration: none;
    font-size: 14px;
    color: #2e2d2f;
    margin-top: 5px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

    .top-panel-user-logout i {
        margin-left: 10px;
    }

    #main{
        position: relative;
        min-height: calc(100vh - 51px);

    }

@media screen and (min-width: 850px) {
    .top-panel-logo.small {
        display: none;
    }

    .top-panel-logo.big {
        display: block;
    }
}

@media screen and (min-width: 1450px) {
    .block-layout {
        padding-left: 15%;
        padding-right: 15%;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: red !important;
}