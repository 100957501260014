.video-main-logo-block {
    text-align: center;
    padding: 25px 0;
}

.video-block {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}

.iframe-wrapper {
    position: relative;
    padding-bottom: 56.20%;
    height: 0;
    overflow: hidden;
    margin: 0 auto;
}

iframe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

h1.video-name {
    margin: 15px 0 0 0;
    font-size: 20px;
    font-weight: 500;
    color: #2d2e2f;
}

h2.video-organization-info {
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    color: #515254;
}

.like-personalizard-button-block {
    text-align: center;
    padding: 50px 0;
}

button.like-personalizard-button {
    background-color: #33B58F;
    color: #ffffff;
    border: 2px solid #33B58F;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 12.5px 30px;
    outline: none;
    border-radius: 50px;
    transition: .25s color, .25s background-color;
}

    button.like-personalizard-button i {
        margin-right: 10px;
    }

    button.like-personalizard-button:hover {
        background-color: transparent;
        color: #33B58F;
    }


@media screen and (max-width: 960px) {
    .video-buttons {
        padding: 0 2%;
    }
}

