.header-statistics {
    border-top: 1px solid #eeeeee;
    padding-top: 30px;
    padding-bottom: 30px;
    display: block;
}

.item-user-info {
    display: none;
    padding: 20px 0 15px 0;
    width: 300px;
}

    .item-user-info i {
        font-size: 35px;
        color: #515254;
        margin-top: 5px;
    }

.user-info-panel {
    margin-left: 20px;
}

.user-info-name {
    display: block;
    font-weight: 500;
    color: #2d2e2f;
}

.user-info-organization {
    font-size: 14px;
    color: #515254;
}

.item-statistics-box {
    width: 180px;
    display: inline-block;
    text-align: center;
    margin: 10px 0;
}

    .item-statistics-box:not(:last-child) {
        border-right: 1px solid #eeeeee;
    }

.item-statistics-box a {
    text-decoration: none;
}

.big-stats-label {
    font-size: 50px;
    font-weight: 500;
    color: #2d2e2f;
}

.small-stats-label {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: 14px;
    color: #515254;
    font-weight: 500;
}

span.stats-mark {
    color: #33B58F;
}

@media screen and (min-width: 820px) {
    .item-statistics {
        width: 100%;
    }
    .item-statistics-box:nth-child(2) {
        border-right: 1px solid #eeeeee;
    }

}


@media screen and (max-width: 400px) {
    .item-statistics-box {
        width: 125px;
        vertical-align: top;
    }

    .item-statistics-box:nth-child(4) {
        border-right: none !important;
    }

    .small-stats-label {
        min-height: 50px;
    }

}

@media screen and (max-width: 599px) {


    .item-statistics-box:nth-child(2) {
        border-right: none !important;
    }

    .item-statistics-box:nth-child(4) {
        border-right: none !important;
    }

}

@media screen and (max-width: 1199px) and (min-width: 1000px) {


    .item-statistics-box:nth-child(5) {
        border-right: none !important;
    }

}

@media screen and (max-width: 999px) and (min-width: 800px) {


    .item-statistics-box:nth-child(4) {
        border-right: none !important;
    }

}

@media screen and (max-width: 799px) and (min-width: 600px) {

    .item-statistics-box:nth-child(3) {
        border-right: none !important;
    }

}


@media screen and (min-width: 1000px) {
    .header-statistics {
        display: flex;
    }

    .item-statistics {
       /*margin-left: 50px;*/
        text-align: center;
    }
}

@media screen and (min-width: 1130px) {
    .item-user-info {
        display: flex;
    }
    } 