.signin-main-logo-block {
    text-align: center;
}

.signin-form {
    width: 300px;
    margin: 0 auto;
    padding: 50px 0 100px 0;
}

.adv-form-label-input-block {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    height: 59px;
}

.adv-form-label-input-block label {
    font-size: 18px;
    transition: .25s color, .25s top, .25s font-size;
    position: relative;
    top: 50px;
    color: #515254;
}

.adv-form-label-input-block input {
    margin-top: 10px;
    border: none;
    padding: 8px 0;
    font-size: 15px;
    font-family: "Ubuntu", sans-serif;
    width: 100%;
    outline: none;
    transition: .25s border;
    border-bottom: 1px solid #eeeeee;
}

.adv-form-label-input-block i {
    position: absolute;
    bottom: 10px;
    right: 0;
    color: #515254;
    transition: .25s color;
}

.form-p-desc {
    position: absolute;
    bottom: -32px;
    font-size: 12px;
    color: #515254;
}

.adv-form-label-input-block input:focus{
    border-color: #33B58F;
}

.adv-form-label-input-block input:focus + label{
    color: #33B58F;
    font-weight: 500;
    font-size: 13px;
    top: 20px;
}

.adv-form-label-input-block input:focus + label + i{
    color: #33B58F;
}

.form-submit-button {
    outline: none;
    background-color: transparent;
    color: #eeeeee;
    border: 2px solid #eeeeee;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 25px;
}

.form-submit-button-active {
    border-color: #33B58F;
    color: #ffffff;
    background-color: #33B58F;
    transition: .25s color, .25s background-color;
}

    .form-submit-button-active:hover {
        border-color: #33B58F;
        color: #33B58F;
        background-color: transparent;
    }

.adv-form-label-input-block .input-filled {
    font-weight: 500;
    font-size: 13px;
    top: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: red !important;
}
input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}