.main-content {
    text-align: center;
    padding-top: 40px;
}

.colored-layout {
    margin: 0 auto 40px auto;
    background-color: #fafafa;
    border-radius: 20px;
    padding: 25px 30px;
    text-align: left;
}

.colored-layout h1 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #2d2e2f;
}

.colored-layout a {
    text-decoration: none;
    color: #33B58F;
}

.colored-layout a:hover {
    cursor: pointer;
}

.form-label-input-block input.image-input {
    padding-right: 40px;
}

.remove-image {
    position: absolute;
    bottom: 7.5px;
    z-index: 2;
    right: 0;
}

.image-input-block {
    position: relative;
    width: 100%;
}

.save-button {
    width: 300px;
    margin: 0 auto 40px auto;
    text-align: center;
}

.form-label-input-block {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

.form-label-input-block label {
    font-weight: 500;
    font-size: 13px;
    transition: .25s color;
}

.form-label-input-block input {
    margin-top: 10px;
    border: 1px solid #eeeeee;
    padding: 8px 17.5px;
    font-size: 15px;
    font-family: "Ubuntu", sans-serif;
    border-radius: 7.5px;
    width: 100%;
    outline: none;
    transition: .25s border;
}

.form-label-input-block input[type="radio"] {
    width: auto;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.radio-button {
    display: inline-block;
}

.form-label-input-block select {
    margin-top: 10px;
    border: 1px solid #eeeeee;
    padding: 8px 17.5px;
    font-size: 15px;
    font-family: "Ubuntu", sans-serif;
    border-radius: 7.5px;
    width: 100%;
    outline: none;
    transition: .25s border;
}

.form-label-input-block input:focus {
    border-color: #33B58F;
}

.form-label-input-block input:focus + label {
    color: #33B58F;
}

.form-label-input-block i {
    position: absolute;
    color: #515254;
    right: -21px;
    font-size: 14px;
    bottom: 15px;
}

.form-label-input-block input.location-search-input:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autocomplete-dropdown-container {
    position: absolute;
    top: 77px;
    z-index: 3;
    -webkit-box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 2.5px 0px rgba(0, 0, 0, 0.3);
}

.form-submit-button {
    outline: none;
    background-color: transparent;
    color: #eeeeee;
    border: 2px solid #eeeeee;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
}

.settings-remove-account {
    font-size: 14px;
    color: #515254;
    margin: 15px 0 30px 0;
}

.settings-remove-account button {
    outline: none;
    background-color: transparent;
    border: none;
    color: #33B58F;
    margin: 0;
    padding: 0;
    font-family: "Ubuntu", sans-serif;
}

.radio-button label {
    background: #eeeeee;
    width: 150px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
}

.radio-button:nth-child(2n+1) label {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.radio-button:nth-child(2n) label {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.radio-btns {
    display: none;
}

.radio-button.active-radio label {
    background: #33B58F;
    color: white;
}

input:disabled {
    background: white;
}

.selected-item {
    padding: 8px;
}

.selected-item:hover {
    background-color: #33B58F;
    cursor: pointer;
    color: white;
}

.select-div {
    position: absolute;
    background: white;
    z-index: 3;
    width: 440px;
    border: 1px solid lightgrey;
}

@media only screen and (max-width: 555px) {
    .select-div {
        width: 76%;
    }
}