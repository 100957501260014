.main-content {
    text-align: center;
    padding-top: 40px;
}

.vertical-link-button {
    text-decoration: none;
    background: #33B58F;
    border-radius: 100px;
    padding: 25px 35px;
    font-size: 20px;
    text-transform: uppercase;
    border: 2px solid #33B58F;
    color: #ffffff;
    outline: none;
    transition: .25s background-color, .25s color;
    font-family: 'Ubuntu', sans-serif;
}

    .vertical-link-button:hover {
        background-color: transparent;
        color: #33B58F;
    }

    .vertical-link-button i {
        margin-right: 10px;
    }

.links-block {
    padding: 40px 0;
}

.horizontal-link-button {
    width: 200px;
    padding: 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Ubuntu', sans-serif;
    background: transparent;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    outline: none;
    font-family: 'Ubuntu', sans-serif;
    margin: 10px;
    transition: .25s border;
}

    .horizontal-link-button:hover {
        border-color: #33B58F;
    }

    .horizontal-link-button i {
        display: block;
        font-size: 30px;
        color: #515254;
        margin-bottom: 20px;
        transition: .25s color;
    }

    .horizontal-link-button:hover i {
        color: #33B58F;
    }