.main-content {
    text-align: center;
    padding-top: 40px;
}

.settings-edit-block {
    width: 100%;
    margin: 0 auto;
    background-color: #fafafa;
    border-radius: 20px;
    padding: 25px 30px;
    text-align: left;
}

    .settings-edit-block h1 {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        color: #2d2e2f;
    }

    .settings-edit-block p {
        font-size: 14px;
        color: #515254;
    }

        .settings-edit-block p span {
            color: #33B58F;
        }

.form-label-input-block {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

    .form-label-input-block label {
        font-weight: 500;
        font-size: 13px;
        transition: .25s color;
    }

    .form-label-input-block input {
        margin-top: 10px;
        border: 1px solid #eeeeee;
        padding: 8px 17.5px;
        font-size: 15px;
        font-family: "Ubuntu", sans-serif;
        border-radius: 7.5px;
        width: 100%;
        outline: none;
        transition: .25s border;
    }

        .form-label-input-block input:focus {
            border-color: #33B58F;
        }

            .form-label-input-block input:focus + label {
                color: #33B58F;
            }

    .form-label-input-block i {
        position: absolute;
        color: #515254;
        right: -21px;
        font-size: 14px;
        bottom: 10px;
    }

.form-submit-button {
    outline: none;
    background-color: transparent;
    color: #eeeeee;
    border: 2px solid #eeeeee;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
}

.settings-remove-account {
    font-size: 14px;
    color: #515254;
    margin: 15px 0 30px 0;
}

    .settings-remove-account button {
        outline: none;
        background-color: transparent;
        border: none;
        color: #33B58F;
        margin: 0;
        padding: 0;
        font-family: "Ubuntu", sans-serif;
    }

@media screen and (min-width: 650px) {
    .settings-edit-block {
        width: 500px;
    }
}