.main-content {
    text-align: center;
    padding-top: 40px;
}

.section-header-1 {
    margin: 0;
    font-size: 19px;
    font-weight: 500;
    padding-left: 31px;
}
.section-header-2 {
    margin: 0;
    font-size: 15px;
    padding-left: 31px;
}

.section-header-3 {
    margin: 0;
    font-size: 12px;
    padding-left: 31px;
}

.items-block {
    padding-top: 5px;
}

.delete-item{ 
    cursor: pointer;
    background: none;
    border: none;
}

.users-item {
    padding: 20px 30px 0 30px;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    margin: 15px 0;
}

.user-name {
    margin: 0;
    font-size: 16px;
    color: #2d2e2f;
}

    .user-name i {
        margin-right: 10px;
        color: #33B58F;
    }

.user-email-controls {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 10px 0;
}

.user-email {
    color: #33B58F;
}

    .user-email i {
        margin-right: 5px;
    }

.user-controls-item {
    display: inline-block;
    margin: 0 0 0 30px;
    color: #515254;
}

    .user-controls-item i {
        margin-right: 5px;
    }
.items-message {
    padding-left: 31px;
}
.user-stats {
    display: flex;
    color: #515254;
    font-size: 14px;
    border-top: 1px solid #eeeeee;
    padding: 5px 0 10px 0;
    margin-top: 10px;
}

    .user-stats span {
        margin-right: 40px;
    }

.user-edit {
    background-color: #fafafa;
    width: calc(100% + 60px);
    position: relative;
    padding: 20px 30px;
    left: -30px;
    border-radius: 20px;
    margin-top: 10px;
    display: none;
}

.page-top-panel {
    padding: 20px 30px;
    background-color: #fafafa;
    border-radius: 20px;
}

.page-top-panel-header {
    display: flex;
    justify-content: space-between;
}

.page-top-panel h1 {
    color: #2d2e2f;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.page-top-panel i {
    color: #515254;
    transition: .25s color, .25s transform;
    cursor: pointer;
    position: relative;
}

.page-top-panel i:hover{
    color: #33B58F;
}

.page-top-panel-body {
    margin-top: 15px;
    display: none;
}

.form-label-input-block {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

    .form-label-input-block label {
        font-weight: 500;
        font-size: 13px;
        transition: .25s color;
    }

    .form-label-input-block input {
        margin-top: 10px;
        border: 1px solid #eeeeee;
        padding: 8px 17.5px;
        font-size: 15px;
        font-family: "Ubuntu", sans-serif;
        border-radius: 7.5px;
        width: calc(100% - 37px);
        outline: none;
        transition: .25s border;
    }

        .form-label-input-block input:focus {
            border-color: #33B58F;
        }

            .form-label-input-block input:focus + label {
                color: #33B58F;
            }

    .form-label-input-block i {
        position: absolute;
        color: #515254;
        right: -21px;
        font-size: 14px;
        bottom: 10px;
    }

.form-submit-button {
    outline: none;
    background-color: transparent;
    color: #eeeeee;
    border: 2px solid #eeeeee;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
}

.form-submit-button-active {
    border-color: #33B58F;
    color: #ffffff;
    background-color: #33B58F;
    transition: .25s color, .25s background-color;
}

    .form-submit-button-active:hover {
        border-color: #33B58F;
        color: #33B58F;
        background-color: transparent;
    }

.form-submit-button-success-fetch {
    outline: none;
    background-color: transparent;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
    color: #00B796;
    border: 2px solid #00B796;
    transition: .25s color, .25s background-color;
}