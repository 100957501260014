footer {
    background-color: #fafafa;
    color: #515254;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    font-size: 14px;
}

    footer a, footer a:hover {
        color: #515254;
    }