
.section-header-1 {
    margin: 0;
    font-size: 19px;
    font-weight: 500;
    padding-left: 31px;
}

.items-block {
    padding-top: 5px;
}

.orgs-item {
    padding: 20px 30px 0 30px;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    margin: 15px 0;
}
.org-name {
    margin: 0;
    font-size: 16px;
    color: #2d2e2f;
}

    .org-name i {
        margin-right: 10px;
        color: #33B58F;
    }

.org-email-controls {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 10px 0;
}

.org-email {
    color: #33B58F;
}

    .org-email i {
        margin-right: 5px;
    }

.org-controls-item {
    display: inline-block;
    margin: 0 0 0 30px;
    color: #515254;
}

    .org-controls-item i {
        margin-right: 5px;
    }

.org-stats {
    display: flex;
    color: #515254;
    font-size: 14px;
    border-top: 1px solid #eeeeee;
    padding: 5px 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

    .org-stats span {
        margin-right: 25px;
    }

.org-edit {
    background-color: #fafafa;
    width: calc(100% + 60px);
    position: relative;
    padding: 20px 30px;
    left: -30px;
    border-radius: 20px;
    margin-top: 10px;
    display: none;
}

    .org-edit .add-btn {
        width: 60px;
        position: absolute;
        right: 5%;
        top: 44px;
    }

    .org-edit .image-input-block .add-btn {
        width: 100px;
        position: absolute;
        right: 5%;
        top: 15px;
    }

.page-top-panel {
    padding: 20px 30px;
    background-color: #fafafa;
    border-radius: 20px;
}

.page-top-panel-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.page-top-panel h1 {
    color: #2d2e2f;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.page-top-panel i {
    color: #515254;
}

.page-top-panel-body {
    margin-top: 15px;
}

.active-item {
    color: #33B58F !important;
}

.valid-field input {
    border-color: #00B796 !important;
}
.nonvalid-field input {
    border-color: #CC0063 !important;
}

.form-label-input-block input.key-input {
    width: 70%;
}


.add-btn {
    border: none;
    background: none;
    color: #eeeeee;
}

.add-btn-active{
    color: #33B58F;
}

.form-label-input-block .add-btn i {
    position: relative;
    right: 0;
    bottom: 0;
    margin-right: 5px;
    color: #eeeeee;
}

.form-label-input-block .add-btn-active i {
    color: #33B58F;
}

.remove-template, .remove-image{
    border: none;
    background: none;
}

.form-label-input-block .remove-template i, .form-label-input-block .remove-image i {
    position: relative;
    right: 0;
    bottom: 0;
    margin-right: 5px;
    color: #33B58F;
}

.form-label-input-block .remove-image i{
    color: #515254;
}

.form-submit-button-success-fetch {
    outline: none;
    background-color: transparent;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
    color: #00B796;
    border: 2px solid #00B796;
    transition: .25s color, .25s background-color;
}