.custom-ui {
    background: white;
    padding: 20px 40px;
    border-radius: 20px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
}

.custom-ui h1{
    font-size: 16px;
}

    .custom-ui h1 i {
        color: #33B58F;
        margin-right: 10px;
    }

.custom-ui p{
    font-size: 14px;
    margin-bottom: 0;
}

    .custom-ui .line {
        width: 100%;
        height: 1px;
        background: #EEEEEE;
        margin: 15px 0;
    }

    .custom-ui button {
        background: transparent;
        border: none;
        font-weight: 500;
        font-size: 14px;
        margin: 0 15px;
    }

    .custom-ui .yes {
        color: #CC0063;
    }

    .custom-ui .yes i {
        margin-right: 8px;
    }

    .custom-ui .no {
        color: #515254;
    }

    .quesPopup{
        position:absolute;

    }

.fa-question-circle:hover > div{
    display: block;
}

.fa-question-circle > div {
    display: none;
    width: 250px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Ubuntu', sans-serif;
    z-index: 2;
    top: -10px;
    position: absolute;
    left: 20px;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.1);
}

.page-top-panel-body i:hover {
    color: #515254 !important;
}

.motion-loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.bg-layer {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, .97);
    position: absolute;
}

.loader-info {
    position: fixed;
    text-align: center;
    width: 300px;
    height: 90px;
    top: calc(50% - 45px);
    left: calc(50% - 150px);
}

    .loader-info p {
        padding-top: 15px;
    }

@media screen and (max-width: 1100px) {
    .fa-question-circle > div {
        left: auto;
        right: 20px;
    }
}