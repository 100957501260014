.main-content {
    text-align: center;
    padding-top: 40px;
}

.section-layout {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 40px;
}

.section-header-1 {
    margin: 0;
    font-size: 19px;
    font-weight: 500;
    padding-left: 31px;
}

.share-btn {
    margin-right: 10px;
    color: #33B58F;
}

.items-block {
    padding-top: 5px;
}

.video-item {
    padding: 20px 30px;
    display: flex;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    margin: 15px 0;
}

.item-in-progress {
    background-color: #fafafa;
}

.video-item-image {
    width: 115px;
    height: 72px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    display: none;
}

.video-item-info {
    position: relative;
    width: 100%;
    left: 0;
}

.video-item-info-header {
    margin: 0;
    font-size: 14px;
    color: #2d2e2f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

    .video-item-info-header i {
        margin-right: 10px;
    }

.video-item-info-in-progress {
    font-size: 13px;
    margin: 5px 0;
    color: #33B58F;
}

.video-item-info-details {
    display: flex;
    color: #515254;
    font-size: 14px;
    border-top: 1px solid #eeeeee;
    padding: 5px 0;
    margin-top: 5px;
}

.video-item-info-time {
    margin-right: 40px;
}

    .video-item-info-time i,
    .video-item-info-date i,
    .video-item-info-seen i,
    .video-item-info-in-progress i {
        margin-right: 5px;
    }

.video-item-info-seen {
    margin-left: 20px;
}

.item-done .video-item-info-header i {
    color: #33B58F;
}

.video-item-info-controls {
    font-size: 13px;
    margin-top: 5px;
}

    .video-item-info-controls a {
        display: inline-block;
        text-decoration: none;
        color: #33B58F;
        margin-right: 15px;
    }

        .video-item-info-controls a.item-delete-link {
            margin-left: 20px;
            margin-right: 0;
            color: #515254;
            position: absolute;
            right: 0;
        }

.page-top-panel {
    padding: 20px 30px;
    background-color: #fafafa;
    border-radius: 20px;
}

.page-top-panel-header {
    display: flex;
    justify-content: space-between;
}

.page-top-panel h1 {
    color: #2d2e2f;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.page-top-panel i {
    color: #515254;
}

.page-top-panel-body {
    margin-top: 15px;
}

.video-item-control {
    background: none;
    border: none;
    outline: none;
}

.video-item-control i{
    margin-right: 5px;
}

.form-label-input-block {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

    .form-label-input-block label {
        font-weight: 500;
        font-size: 13px;
        transition: .25s color;
    }

.form-label-input-block textarea {
    margin-top: 10px;
    height: 150px;
    border: 1px solid #eeeeee;
    padding: 8px 17.5px;
    font-size: 15px;
    font-family: "Ubuntu", sans-serif;
    border-radius: 7.5px;
    width: 100%;
    outline: none;
    transition: .25s border;
}

.form-label-input-block textarea:focus {
    border-color: #33B58F;
}

.form-label-input-block textarea:focus + label {
    color: #33B58F;
}


.form-label-input-block input {
        margin-top: 10px;
        border: 1px solid #eeeeee;
        padding: 8px 17.5px;
        font-size: 15px;
        font-family: "Ubuntu", sans-serif;
        border-radius: 7.5px;
        width: 100%;
        outline: none;
        transition: .25s border;
    }

        .form-label-input-block input:focus {
            border-color: #33B58F;
        }

            .form-label-input-block input:focus + label {
                color: #33B58F;
            }

    .form-label-input-block i {
        position: absolute;
        color: #515254;
        right: -21px;
        font-size: 14px;
        bottom: 10px;
    }

.form-submit-button {
    outline: none;
    background-color: transparent;
    color: #eeeeee;
    border: 2px solid #eeeeee;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12.5px 20px;
    font-weight: bold;
    width: 300px;
    position: relative;
    left: calc(50% - 150px);
    border-radius: 50px;
    margin-top: 15px;
}

.autocomplete > div > div {
    padding: 5px 0 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

    .autocomplete > div > div > div {
        padding: 5px 10px;
        color: #2d2e2f;
        background-color: white !important;
    }

        .autocomplete > div > div > div:hover{
            background-color: #33B58F !important;
            color: white;            
        }

.form-submit-button-active {
    border-color: #33B58F;
    color: #ffffff;
    background-color: #33B58F;
    transition: .25s color, .25s background-color;
}

    .form-submit-button-active:hover {
        border-color: #33B58F;
        color: #33B58F;
        background-color: transparent;
    }

.pagination {
    text-align: center;
    max-width: 155px;
    margin: 0 auto;
    display: block
}

    .pagination > * {
        display: inline-block;
        font-size: 15px;
    }

.pagination-items {
    margin: 0 20px;
    font-size: 16px;
}

.pagination-item-active {
    color: #33B58F;
}

.pagination-item {
    display: inline-block;
    margin: 0 5px;
}

.pagination-active {
    color: #33B58F;
    cursor: pointer;
}

.pagination-inactive {
    color: #eeeeee;
}

.pagination i {
    margin-top: 5px;
}

.item-delete-link {
    position: absolute;
    right: 0;
}

.autocomplete div{z-index: 2;}

.sort-menu {
    position: absolute;
    font-size: 13px;
    text-align: left;
    background: white;
    z-index: 3;
    border: 2px solid #fafafa;
    border-radius: 8px;
}

.sort-item {
    height: 25px;
    padding: 4px 26px;
    font-size: 12px;
}

.sort-item:hover {
    background: #fafafa;
}

.sort-item:active {
    color: red;
}

@media screen and (min-width: 650px) {
    .section-layout {
        width: 500px;
    }
    .video-item-image {
        display: block;
    }
    .video-item-info {
        width: calc(100% - 145px);
        left: 30px;
    }
}